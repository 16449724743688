import { useEffect } from "react";
import { Link } from "react-router-dom";
import PhotoHeader from "../PhotoHeader/PhotoHeader";
import "./PriceList.css";

function PriceList() {
  useEffect(() => {
    document.title =
      "Gabinet Terapii Psychologicznej i Hipnoterapii Współczesnej Iwona Krezymon | Usługi Terapeutyczne";
  }, []);

  return (
    <section className="default-page">
      <PhotoHeader title="Usługi Terapeutyczne" imageName="background.jpg" />
      <div className="content">
        <article>
          <div className="text">
            <h3>Konsultacje Psychologiczne</h3>
            <ul className="main-ul">
              <li>
                <b>Pierwsza konsultacja (60 minut)</b> - Indywidualne spotkanie
                mające na celu zrozumienie problemów i potrzeb klienta. Wstępna
                diagnoza oraz planowanie dalszej terapii.
              </li>
              <li>
                <b>Kolejne konsultacje (60 minut)</b> - Kontynuacja pracy nad
                zidentyfikowanymi problemami. Regularne sesje wspierające proces
                terapeutyczny i rozwój osobisty.
              </li>
            </ul>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Terapia Indywidualna</h3>
            <ul className="main-ul">
              <li>
                <b>Sesja terapeutyczna (60 minut)</b> - Intensywna praca nad
                długoterminowymi celami terapeutycznymi. Techniki i strategie
                pomagające w radzeniu sobie z emocjami, stresem i wyzwaniami
                życiowymi.
              </li>
              <li>
                <b>Pakiet 5 sesji</b> - Korzystniejsza opcja dla osób pragnących
                regularnej i długofalowej pracy nad sobą. Pakiet umożliwia
                systematyczne osiąganie postępów terapeutycznych.
              </li>
              <li>
                <b>Pakiet 10 sesji</b> - Najbardziej opłacalna opcja dla
                klientów, którzy chcą zainwestować w swój rozwój osobisty i
                zdrowie psychiczne. Regularne spotkania wspomagające trwałe
                zmiany i poprawę jakości życia.
              </li>
            </ul>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Hipnoterapia</h3>
            <ul className="main-ul">
              <li>
                <b>Sesja hipnoterapeutyczna (45 minut)</b> - Techniki hipnozy
                stosowane w celu redukcji stresu, lęków, oraz wzmocnienia
                pozytywnych wzorców myślenia. Skuteczna metoda pracy z
                podświadomością.
              </li>
              <li>
                <b>Pakiet 3 sesji</b> - Idealny dla osób chcących głębiej
                zanurzyć się w proces hipnoterapeutyczny. Umożliwia skuteczne
                przyswojenie i utrwalenie technik relaksacyjnych i
                terapeutycznych.
              </li>
              <li>
                <b>Pakiet 5 sesji</b> - Kompleksowa praca z wykorzystaniem
                hipnozy. Pakiet pozwala na pełne wykorzystanie potencjału
                hipnoterapii w osiąganiu długoterminowych celów.
              </li>
            </ul>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Specjalizacje Terapeutyczne</h3>
            <ul className="main-ul">
              <li>
                <b>Radzenie sobie z emocjami</b> - Nauka rozpoznawania,
                zrozumienia i regulacji emocji. Praca nad zdrowymi reakcjami
                emocjonalnymi i budowanie większej odporności psychicznej.
              </li>
            </ul>
          </div>
        </article>
        <Link to="/kontakt" id="contactBtn" className="btn">
          Umów wizytę
        </Link>
      </div>
    </section>
  );
}

export default PriceList;
