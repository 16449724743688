import { useEffect } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import IntersectionWrapper from "../IntersectionWrapper/IntersectionWrapper";
import PhotoHeader from "../PhotoHeader/PhotoHeader";
import "./Contact.css";

function Contact() {
  useEffect(() => {
    document.title =
      "Gabinet Terapii Psychologicznej i Hipnoterapii Współczesnej Iwona Krezymon | Kontakt";
  }, []);

  return (
    <section className="contact-page">
      <PhotoHeader title="Kontakt" imageName="background.jpg" />
      <IntersectionWrapper>
        <article className="contact">
          <div className="text">
            <p>
              Aby umówić się na sesję lub uzyskać więcej informacji, proszę o
              kontakt:
            </p>
          </div>
          <ul className="info-tiles">
            <li className="tile hidden">
              <a href="tel:+48609119899">
                <BsFillTelephoneFill />
                <span className="type">telefon</span>
                <span className="info">+48 609 119 899</span>
              </a>
            </li>
            <li className="tile hidden">
              <a href="mailto:klanik@interia.pl">
                <IoIosMail />
                <span className="type">e-mail</span>
                <span className="info">klanik@interia.pl</span>
              </a>
            </li>
            <li className="tile hidden">
              <FaMapMarkerAlt />
              <span className="type">adres</span>
              <span className="info">86-300 Grudziądz</span>
              <span className="info">ul. Al. Wigury 1</span>
            </li>
          </ul>
          <div className="text">
            <p>
              Zapraszam do skorzystania z mojej oferty i wspólnego odkrywania
              drogi do lepszego zarządzania emocjami oraz radzenia sobie w
              sytuacjach kryzysowych.
            </p>
          </div>
        </article>
        <div className="map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.448463623577!2d18.75406405407834!3d53.47831640119533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4702cfd7d9343863%3A0x67e3fd7bfcdf16f!2saleja%20Wigury%201%2C%2086-300%20Grudzi%C4%85dz!5e0!3m2!1spl!2spl!4v1690719761294!5m2!1spl!2spl"
            width="100%"
            height="350"
            style={{ border: 0 }}
            loading="lazy"
          ></iframe>
        </div>
      </IntersectionWrapper>
    </section>
  );
}

export default Contact;
