import { useEffect } from "react";
import { Link } from "react-router-dom";
import PhotoHeader from "../PhotoHeader/PhotoHeader";
import "./Offer.css";

function Offer() {
  useEffect(() => {
    document.title =
      "Gabinet Terapii Psychologicznej i Hipnoterapii Współczesnej Iwona Krezymon | Oferta";
  }, []);

  return (
    <section className="default-page">
      <PhotoHeader title="Oferta" imageName="background.jpg" />
      <div className="content">
        <article>
          <div className="text">
            <p>
              Zapraszam do skorzystania z moich usług psychologicznych i
              hipnoterapeutycznych, które są dostosowane do indywidualnych
              potrzeb każdego klienta. Oferuję profesjonalną pomoc i wsparcie w
              radzeniu sobie z różnorodnymi problemami psychicznymi oraz pomoc w
              osiąganiu osobistych celów.
            </p>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Konsultacje psychologiczne</h3>
            <p>
              Konsultacje psychologiczne są dedykowane osobom borykającym się z
              problemami emocjonalnymi, relacyjnymi czy zawodowymi. Oferuję
              wsparcie w zakresie:
            </p>
            <ul className="main-ul">
              <li>Depresji i lęków</li>
              <li>Stresu i wypalenia zawodowego</li>
              <li>Kryzysów życiowych</li>
              <li>Problemów w relacjach interpersonalnych</li>
              <li>Zaburzeń nastroju</li>
            </ul>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Terapia indywidualna</h3>
            <p>
              Terapia indywidualna jest procesem, który pozwala na głębsze
              zrozumienie siebie i swoich emocji. Pracujemy nad:
            </p>
            <ul className="main-ul">
              <li>Samooceną i pewnością siebie</li>
              <li>Radzeniem sobie z traumami</li>
              <li>Rozwojem osobistym</li>
              <li>Poprawą jakości życia</li>
            </ul>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Hipnoterapia</h3>
            <p>
              Hipnoterapia to skuteczna metoda pracy z podświadomością, która
              może pomóc w:
            </p>
            <ul className="main-ul">
              <li>
                Zwalczaniu nałogów (np. palenie papierosów, jedzenie
                emocjonalne)
              </li>
              <li>Redukcji stresu i lęków</li>
              <li>Poprawie koncentracji i pamięci </li>
              <li>Leczeniu bezsenności</li>
              <li>Zwiększeniu motywacji i osiąganiu celów</li>
            </ul>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Korzyści z Terapii</h3>
            <ul>
              <li>
                <b>Redukcja stresu</b>: Nauczysz się skutecznych technik relaksacyjnych, które pomogą Ci w codziennym życiu.
              </li>
              <li>
                <b>Zwiększenie odporności psychicznej</b>: Praca nad budowaniem siły wewnętrznej i elastyczności emocjonalnej.
              </li>
              <li>
                <b>Lepsze zarządzanie emocjami</b>: Nauka rozpoznawania i kontrolowania emocji, co pozwoli Ci lepiej radzić sobie w trudnych sytuacjach.
              </li>
              <li>
                <b>Poprawa jakości życia</b>: Osiągnięcie większej równowagi emocjonalnej i mentalnej, co przełoży się na lepsze relacje interpersonalne i większe zadowolenie z życia.
              </li>
            </ul>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Dlaczego warto wybrać moje usługi?</h3>
            <ul>
              <li>
                <b>Indywidualne podejście</b>: Każdy klient jest dla mnie
                wyjątkowy i otrzymuje pełne zaangażowanie oraz wsparcie
                dostosowane do jego potrzeb.
              </li>
              <li>
                <b>Profesjonalizm</b>: Jako wykwalifikowany psycholog i
                certyfikowany hipnoterapeuta, gwarantuję wysoką jakość
                świadczonych usług.
              </li>
              <li>
                <b>Empatia i zrozumienie</b>: Moim celem jest tworzenie
                bezpiecznej i wspierającej przestrzeni, w której każdy może
                poczuć się swobodnie.
              </li>
            </ul>
          </div>
        </article>
        <Link to="/kontakt" id="contactBtn" className="btn">Umów wizytę</Link>
      </div>
    </section>
  );
}

export default Offer;
