import { useEffect } from "react";
import { Link } from "react-router-dom";
import MenuTiles from "../MenuTiles/MenuTiles";
import PhotoHeader from "../PhotoHeader/PhotoHeader";
import StaticPhoto from "../StaticPhoto/StaticPhoto";
import "./HomePage.css";

function HomePage() {
  useEffect(() => {
    document.title =
      "Gabinet Terapii Psychologicznej i Hipnoterapii Współczesnej Iwona Krezymon";
  }, []);

  return (
    <section className="home-page">
      <PhotoHeader
        title="Iwona Krezymon"
        subtitle="Psycholog & Hipnoterapeuta"
        imageName="background.jpg"
      />
      <MenuTiles />
      <StaticPhoto />
      <article className="paragraph">
        <div className="text">
          <h3>Metody terapeutyczne</h3>
          <h4>Hipnoterapia współczesna</h4>
          <p>
            Hipnoterapia współczesna to skuteczna metoda terapeutyczna
            wykorzystująca stan hipnozy do pracy nad różnymi problemami i
            wyzwaniami. W przeciwieństwie do popularnych mitów, hipnoza jest
            naturalnym stanem umysłu, który może być wykorzystany do głębokiej
            relaksacji oraz pracy nad zmianami w myśleniu, emocjach i
            zachowaniach.
          </p>
          <p>
            Jako hipnoterapeutka i psycholog, moim celem jest zapewnienie
            bezpiecznego i wspierającego środowiska, w którym możesz poczuć się
            swobodnie dzielić się swoimi problemami i celami terapeutycznymi.
            Wykorzystując techniki hipnoterapii oraz moje doświadczenie
            psychologiczne, pomagam ludziom w pokonywaniu lęków, stresu,
            depresji, uzależnień oraz innych trudności życiowych. Moja praktyka
            opiera się na podejściu holistycznym, co oznacza, że biorę pod uwagę
            wszystkie aspekty Twojego życia - ciało, umysł i duszę - aby
            zapewnić kompleksową i skuteczną pomoc terapeutyczną.
          </p>
          <h4>Terapia Skoncentrowana na Rozwiązaniach (TSR)</h4>
          <p>
            W mojej praktyce terapeutycznej stosuję również narzędzia Terapii
            Skoncentrowanej na Rozwiązaniach (TSR), która jest skuteczną metodą
            terapeutyczną koncentrującą się na poszukiwaniu i wzmocnieniu już
            istniejących sił i zasobów pacjenta. TSR skupia się na wyznaczaniu
            celów terapeutycznych oraz poszukiwaniu konkretnych rozwiązań
            problemów, zamiast analizowania przeszłości czy negatywnych
            doświadczeń.
          </p>
          <p>
            Wykorzystując zarówno techniki hipnoterapii, jak i narzędzia Terapii
            Skoncentrowanej na Rozwiązaniach, moim celem jest dostarczenie Ci
            skutecznej i wszechstronnej pomocy w radzeniu sobie z życiowymi
            wyzwaniami. Poprzez współpracę i użycie różnorodnych metod
            terapeutycznych, pomagam Ci odkryć własne zasoby oraz znaleźć drogę
            do pozytywnych zmian i lepszej jakości życia.
          </p>
        </div>
        <Link to="/kontakt" id="contactBtn" className="btn">
          Umów wizytę
        </Link>
      </article>
    </section>
  );
}

export default HomePage;
