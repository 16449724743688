import { useEffect } from "react";
import { Link } from "react-router-dom";
import PhotoHeader from "../PhotoHeader/PhotoHeader";
import "./AboutMe.css";

function AboutMe() {
  useEffect(() => {
    document.title =
      "Gabinet Terapii Psychologicznej i Hipnoterapii Współczesnej Iwona Krezymon | O Mnie";
  }, []);

  return (
    <section className="default-page about-me-page">
      <PhotoHeader title="O mnie" imageName="background.jpg" />
      <div className="content about-me">
        <article>
          <div className="text">
            <p>
              Nazywam się Iwona Krezymon, jestem magistrem psychologii oraz
              certyfikowaną terapeutką hipnozy współczesnej. Ukończyłam studia
              psychologiczne na Uniwersytecie SWPS w Sopocie. Moja praca opiera
              się na głębokim zrozumieniu ludzkich emocji, myśli i zachowań,
              doświadczeniu i nieustannemu doskonaleniu swoich umiejętności,
              oferuję wsparcie w radzeniu sobie z różnorodnymi problemami
              psychicznymi oraz pomoc w rozwoju osobistym.
            </p>
          </div>
          <img
            className="large"
            src="images/photo_1.jpg"
            alt="Iwona Krezymon"
            loading="lazy"
          />
          <div className="text">
            <p>
              W swojej praktyce wykorzystuję różnorodne techniki terapeutyczne,
              dostosowując metody do indywidualnych potrzeb każdego klienta.
              Hipnoterapia, którą się specjalizuję, jest skutecznym narzędziem w
              pracy z podświadomością, pomagając w pokonywaniu lęków, nawyków
              czy traum.
            </p>
            <p>
              Zapraszam do kontaktu osoby, które poszukują profesjonalnej pomocy
              psychologicznej oraz wsparcia w osiąganiu pełni swojego
              potencjału. Wspólnie możemy pracować nad poprawą jakości życia,
              zrozumieniem siebie i osiąganiem wyznaczonych celów.
            </p>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Moja misja</h3>
            <p>
              Moja pasja do pracy z ludźmi sprawia, że nieustannie rozwijam
              swoje umiejętności i poszukuję nowych metod terapeutycznych, które
              mogą przynieść ulgę i wsparcie moim pacjentom. Jestem przekonana,
              że każdy człowiek ma w sobie siłę do zmiany i wzrostu, a moim
              celem jest wspieranie go w tej podróży.
            </p>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Indywidualne podejście</h3>
            <p>
              Jedną z kluczowych zasad mojej pracy jest indywidualne podejście
              do każdego klienta. Rozumiem, że każdy człowiek jest inny i każdy
              ma własną unikalną historię życiową oraz cele terapeutyczne.
              Dlatego też dostosowuję moje metody terapeutyczne do potrzeb i
              preferencji każdej osoby, zapewniając spersonalizowaną i skuteczną
              opiekę terapeutyczną.
            </p>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Moje wartości</h3>
            <ul>
              <li>
                <b>Empatia</b> - W mojej pracy terapeutycznej kładę ogromny
                nacisk na empatię. Rozumiem, że każdy człowiek ma swoje własne
                doświadczenia, emocje i wyzwania życiowe, dlatego zawsze staram
                się podejść do moich pacjentów z pełnym zrozumieniem i
                współczuciem.
              </li>
              <li>
                <b>Szacunek</b> - Szanuję moich pacjentów jako jednostki i ich
                indywidualność. Niezależnie od tego, jakie wyzwania przynoszą,
                zawsze traktuję ich z szacunkiem i godnością.
              </li>
              <li>
                <b>Autentyczność</b> - Jestem autentyczna w mojej pracy
                terapeutycznej. Nie udaję kogoś, kim nie jestem, i zawsze staram
                się być prawdziwa i uczciwa w moich interakcjach z pacjentami.
              </li>
              <li>
                <b>Otwartość</b> - Jestem otwarta na różnorodność i różnorodność
                doświadczeń życiowych moich pacjentów. Niezależnie od tego,
                jakie są ich tło kulturowe, religijne czy seksualne, zawsze
                jestem gotowa ich przyjąć z otwartymi ramionami.
              </li>
            </ul>
          </div>
        </article>
        <article>
          <div className="photos">
            <img src="images/photo_2.jpg" alt="Iwona Krezymon" loading="lazy" />
            <img src="images/photo_3.jpg" alt="Iwona Krezymon" loading="lazy" />
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Współpraca i partnerstwo</h3>
            <p>
              Wierzę w współpracę i partnerstwo z moimi klientami. Nie traktuję
              moich klientów jako biernych odbiorców terapii, ale jako aktywnych
              uczestników swojej własnej terapii. Razem z nimi pracujemy nad
              ustalaniem celów terapeutycznych i poszukiwaniem rozwiązań.
            </p>
          </div>
        </article>
        <article>
          <div className="text">
            <h3>Moje kwalifikacje</h3>
            <ul>
              <li>
                <b>Psychologia</b>: Uniwersytet SWPS w Sopocie
              </li>
              <li>
                <b>Certyfikowana hipnoterapeutka</b>: Centrum Rozwoju Osobistego
                w Warszawie
              </li>
            </ul>
          </div>
        </article>
        <Link to="/kontakt" id="contactBtn" className="btn">
          Umów wizytę
        </Link>
      </div>
    </section>
  );
}

export default AboutMe;
