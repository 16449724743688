import "./Footer.css";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-wrapper">
      <footer>
        Copyright &copy; {currentYear} Gabinet Terapii Psychologicznej i
        Hipnoterapii Współczesnej Iwona Krezymon
      </footer>
    </div>
  );
}
